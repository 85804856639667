import * as React from "react";
import { PageLayout } from "../components/layout/layout";
import { WheelsPageComponent } from "../components/wheelsPage/wheelsPage";

// markup
const WheelsPage = () => {
  return (
    <PageLayout>
      <WheelsPageComponent />
    </PageLayout>
  );
};

export default WheelsPage;
