import React from "react";
import Container from "../container/container";
import { SearchByWheel } from "../searchByWheel/searchByWheel";
import carPhotoDesktop from "../../images/wheelsDesktop.webp";
import carPhotoMobile from "../../images/wheelsDesktop.webp";

import * as StyleComponents from "./wheelsPage.module.scss";

// markup
export const WheelsPageComponent = () => {
  return (
    <Container>
      <picture>
        <source
          media="(max-width: 1200px)"
          type="image/jpeg"
          srcSet={carPhotoMobile}
        />
        <img
          src={carPhotoDesktop}
          alt="Jeep 4x4 canada offroad with big tires"
          className={StyleComponents.mainImage}
        />
      </picture>
      <SearchByWheel />
    </Container>
  );
};
